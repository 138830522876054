import React from "react"
import * as styles from "./SectionAlternateGrid3.module.scss"
import { RichText } from "../../Layout/RichText/RichText"
import Img from 'gatsby-image';

const SectionAlternateGrid3 = ({ data }) => {

    const {image,sourceText,statementArray,iconImage} = data

    return (
        <div className={styles.gridContainer}>
           <div className={styles.imgContainer}>
                {image && <Img className={`styles.Img`} fluid={image.fluid} alt={image?.title} />}
           </div>
           <div className={styles.txtContainer}>
                {
                    statementArray.map((statement,index)=>{
                        return (
                            <div className={styles.bullets} key={index}>
                                <div className={styles.icon}>
                                    {iconImage && <Img fluid={iconImage.fluid} alt={iconImage?.title} />}
                                </div>
                                <div className={styles.statement}>
                                    <p className={styles.title}>{statement.title}</p>
                                </div>
                            </div>
                        )
                    })
                }
                
                {sourceText && 
                <div className={styles.sourceText}>
                    <RichText content={sourceText} />
                </div>} 
           </div>
        </div>
        
    )
}

export default SectionAlternateGrid3;
