import React from "react";
import * as styles from "./pannelHero.module.scss";
import Img from "gatsby-image";

const HeroPannelSE = ({ subtitle, title, fluid, fluidText = null, firstChild = false }) => {
  return (
    <div className={styles.hero_container}>
      <Img
        loading={firstChild ? "eager" : "lazy"}
        fadeIn={firstChild ? false : true}
        fluid={fluid}
        alt={fluidText ? fluidText : title ? title : "generic image"}
      />
      <div className={styles.heading_title}>
        <p className={styles.sub_heading}>{subtitle}</p>
        <h2 className={styles.title_heading}>{title}</h2>
      </div>
    </div>
  );
};

export default HeroPannelSE;

