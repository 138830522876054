
import React from 'react';
import * as styles from './PackShot.module.scss';
import Img from 'gatsby-image';

import { useSearchQuery } from '../../../../hooks/layout/useSearchQuery';

const PackShotSection = ({ data }) => {
    const { heading, infographicsIcon, infographicsProducts } = data;


    const { leftLeaf, rightLeaf } = useSearchQuery()
    return (
        <div className={styles.multipleImagesContainer}>
            <div className='container_lg'>
                {heading && <h2 className={styles.heading}>{heading}</h2>}
                <div className={styles.gridImages}>
                    {infographicsIcon && infographicsIcon[0].imageSection.map((imageSection, index) => {

                        return (

                            <div key={index} className={styles.media}>
                                <Img
                                    className={styles.media}
                                    fluid={imageSection.fluid}
                                    alt={imageSection.title}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className={`${styles.packs}`}>
                    {infographicsProducts.map((item, index) => {
                        const { imageSection, imageSectionMobile, packshotTitle } = item;

                        //Split packshotTitle
                        const titleWords = packshotTitle.split(' ');
                        const isTwoWords = titleWords.length === 2;

                        return (
                            <div className={`${styles.packshot_packs}`} key={index}>
                                <div>
                                    <Img
                                        className={styles.productImage}
                                        fluid={imageSection[0].fluid}
                                        alt={imageSection[0].title}
                                    />
                                    <Img
                                        className={`${imageSectionMobile ? styles.desktopImage : styles.heroImage}`}
                                        fluid={imageSectionMobile[0].fluid}
                                        alt={imageSectionMobile[0].title}

                                    />
                                    <div className={styles.asset_title}>
                                        <p className={styles.packshotTitle}>
                                            {isTwoWords
                                                ? (
                                                    <div>
                                                        <span className={styles.underlinedText}>{packshotTitle}</span>
                                                    </div>
                                                )
                                                : (
                                                    <div>
                                                        <span>{titleWords.slice(0, -2).join(' ')}</span>
                                                        <br />
                                                        <span className={styles.underlinedText}>{titleWords.slice(-2).join(' ')}</span>
                                                    </div>
                                                )
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {rightLeaf.fluid && (
                        <Img
                            fluid={rightLeaf.fluid}
                            alt={rightLeaf.alt || "Leaf Image"}
                            className={styles.right_leaf}
                        />
                    )}
                    {leftLeaf.fluid && (
                        <Img
                            fluid={leftLeaf.fluid}
                            alt={leftLeaf.alt || "Leaf Image"}
                            className={`${styles.left_leaf}`}
                        />
                    )}

                </div>
            </div>
        </div>
    );

};



export default PackShotSection;