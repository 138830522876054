// extracted by mini-css-extract-plugin
export var bar = "YoutubeSection-module--bar--72b83";
export var featured_section_img = "YoutubeSection-module--featured_section_img--e186f";
export var featured_video_container = "YoutubeSection-module--featured_video_container--01b5e";
export var homeDesc = "YoutubeSection-module--home-desc--c6203";
export var homeVideo = "YoutubeSection-module--home-video--73d53";
export var play_btn = "YoutubeSection-module--play_btn--5e723";
export var show = "YoutubeSection-module--show--2013c";
export var video = "YoutubeSection-module--video--d5439";
export var videoContainer = "YoutubeSection-module--video-container--8d679";
export var videoDescription = "YoutubeSection-module--video-description--5795d";
export var videoTitle = "YoutubeSection-module--video-title--7e0a3";
export var video_lg = "YoutubeSection-module--video_lg--493ff";
export var video_wrapper = "YoutubeSection-module--video_wrapper--de77f";