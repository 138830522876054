import React from "react";
import * as styles from "./SectionMultipleImages.module.scss";
import Img from 'gatsby-image';

const SectionMultipleImages = ({ data }) => {
    const { heading, subHeading, mediaCaptions } = data;

    return (
        <div className={styles.multipleImagesContainer}>
            {heading && <h2 className={styles.heading}>{heading}</h2>}
            {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
            <div className={styles.imageTable}>
                {mediaCaptions.map((media, index) => (
                    <div key={index} className={styles.imageContainer}>
                        <Img fluid={media.asset.fluid} alt={media.asset.title} />
                        <div className={styles.captionContainer}>
                            {media.captionTitle &&
                            <h3 className={styles.captionTitle}>{media.captionTitle}</h3>
                            }
                            { media.captionDesc &&
                            <p className={styles.captionDesc}>{media.captionDesc}</p>
                            }
                        </div>
                    </div>
                ))}
            </div>
            
        </div>
    );
};

export default SectionMultipleImages;
