import React from "react"
import * as styles from "./SectionHighlightedDescription.module.scss"

import { RichText } from "../../Layout/RichText/RichText"

const SectionHighlightedDescription = ({ data }) => {


    const {
        disclaimerText,
        simpleText,
        coloredText
    } = data

    return (
        <div className={styles.highlightedContainer}>
            {disclaimerText && <div className={styles.disclaimer}>
                <RichText content={disclaimerText} />
            </div>}
            {simpleText && <div className={styles.simpleText}>
                <RichText content={simpleText} />
            </div>}
            {coloredText && <div className={styles.coloredText}> 
                <p>{coloredText.coloredText}</p>
            </div>}
        </div>
        
    )
}

export default SectionHighlightedDescription
