import React from "react"
import ImageSection from "../ImageSection/ImageSection"
import * as styles from "./alternateImg.module.scss"

const alternateImagesection = ({
  slideImages

}) => {
  return (
   <div className={styles.sectionContainer}>
      {slideImages.map((slide) => {
        return (
          <ImageSection
          image={slide.imageSection}
          />
        )
      })}
    </div>
  )
}
export default alternateImagesection
