import React, { useEffect } from "react"
import * as styles from "./reccomendedProducts.module.scss"
import Slider from "react-slick"
import ReccomendedProduct from "../ReccomendedProduct/ReccomendedProduct"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
//import "swiper/css/navigation";
import "swiper/css/pagination";

//import { Navigation, Pagination } from "swiper";
import { Pagination } from "swiper";

const ReccomendedProducts = ({
    reccomendedProductsData,
    title,
    showButton = true,
}) => {
    useEffect (()=>{
        document.documentElement.style.setProperty("--swiper-navigation-color", "#000")
        document.documentElement.style.setProperty("--swiper-pagination-color", "#000")
    },[])
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     responsive: [
    //         {
    //             breakpoint: 560,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true,
    //             },
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 2.2,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true,
    //             },
    //         },
    //     ],
    // }
    const option = {
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
        slidesPerGroupSkip: 1,
        grabCursor: true,
        keyboard: {
            enabled: true
        },
        pagination: {
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 3,
                spaceBetween: 40
            },
            1400: {
                slidesPerView: 3,
                spaceBetween: 70
            }
        },
        modules: [Pagination],
        slidesPerView: 3,
    }
    return (
        <div className={styles.reccomendedProducts}>
            {title && (
                <h2 className={`yellow_border spacer__01 ${styles.productSliderTitle}`}>{title}</h2>
            )}
            {/* <Slider {...settings}>
                {reccomendedProductsData.map((product,index) => {
                    if(product?.productImg && product?.title && product.slug){
                        return (
                            <ReccomendedProduct
                                key={index}
                                fluid={product?.productImg?.fluid}
                                text={product?.title}
                                showButton={showButton}
                                to={product.slug}
                            />
                        )    
                    }
                })}
            </Slider> */}
            <Swiper {...option}>
                {reccomendedProductsData.map((product, index) => {
                    if (product?.productImg && product?.title && product.slug) {
                        return (
                            <SwiperSlide key={index}>
                                <div className={`styles.productCard`}>
                                    <ReccomendedProduct

                                        fluid={product?.productImg?.fluid}
                                        text={product?.title}
                                        showButton={showButton}
                                        to={product.slug}
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    }
                })}
            </Swiper>
        </div>
    )
}

export default ReccomendedProducts
