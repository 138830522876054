import Img from "gatsby-image"
import * as styles from "./FinestTeaBanner.module.scss";
import React from "react"
import Helmet from 'react-helmet';
import { useSearchQuery } from "../../../../../hooks";


const FinestTeaBanner = ({ fluid, fluidMob, heroTitle, heroTitleMobile }) => {

    return (
        <>
            {fluidMob &&
                <Helmet>
                    <link rel="preload" as="image" media="(min-width: 400.1px) and (max-width: 767px)" href={`${fluidMob.fluid.srcSet.split(/\s*,\s*/).slice(-1).join().split(" ")[0]}`} />
                    <link rel="preload" as="image" media="(max-width: 400px)" href={`${fluidMob.fluid.srcSet.split(/\s*,\s*/).slice(-2).join().split(" ")[0]}`} />
                </Helmet>
            }
            <div className={styles.imgContainer}>
                {fluidMob &&
                    <Img
                        fluid={fluidMob.fluid}
                        className={`${styles.mobImage}`}
                        alt={heroTitleMobile ? heroTitleMobile : "Hero Image"}
                        loading="lazy"
                        fadeIn={true}
                    />
                }
                <Img
                    fluid={fluid.fluid}
                    className={`${fluidMob ? styles.deskImage : styles.heroImg}`}
                    alt={heroTitle ? heroTitle : "Hero Image"}
                    loading="eager"
                    fadeIn={true}
                />
            </div>
        </>
    )
}

export default FinestTeaBanner