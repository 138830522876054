import React from "react"
import * as styles from "./textComponent.module.scss"
import {RichText} from '../../Layout/RichText/RichText'
const TextComponent = ({ text }) => {
  return (
    <div className={styles.text_container}>
      <RichText content={text}/> 
    </div>
  )
}

export default TextComponent
