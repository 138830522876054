import Img from "gatsby-image"
import * as styles from "./InfographicsHeroNarrow.module.scss"
import React from "react"
import { useLocalPath } from "../../../../hooks/layout/useLocalPath"
import { Link } from "gatsby"


const InfographicsHeroNarrow = ({ fluid,fluidMob,fluidTitle,slug,fluidText }) => {
    
    return (
        <div className={styles.imgContainer}>
            {/* <h1>InfographicsHero</h1> */}
            <div>
                {fluid && (
                    !slug ? (
                        <Img fluid={fluid.fluid} className={styles.desktopImg} alt ={fluidText} />
                    ) : (
                        <Link to={useLocalPath(slug)}>
                            <Img fluid={fluid.fluid} className={styles.desktopImg} alt ={fluidText} />
                        </Link>
                    ))
                }
            </div>

            <div>
                {/* {fluidMob ? <Img
                    fluid={fluidMob.fluid}
                    className={` ${styles.mobileImage}`} /> :
                    <Img
                        fluid={fluid.fluid}
                        className={` ${styles.mobileImage}`} />} */}

                {fluidMob ? (
                    !slug ? (
                        <Img fluid={fluidMob.fluid} className={styles.mobileImage} alt ={fluidText}/>
                    ) : (
                        <Link target="_blank" to={useLocalPath(slug)}>
                            <Img fluid={fluidMob.fluid} className={styles.mobileImage} alt ={fluidText}/>
                        </Link>
                    ) 
                    ) :
                    
                    <Img
                        fluid={fluid.fluid}
                        className={` ${styles.mobileImage}`} alt ={fluidText} />
                    
                }
            </div>
        </div>
    )
}

export default InfographicsHeroNarrow
