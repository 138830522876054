import React from "react"
import * as styles from "./featuredRecipe.module.scss"
import Img from "gatsby-image"
import Banner from "../Banner/Banner"

const FeaturedRecipe = ({ title, fluid, fluidTitle = null, subtitle, thumbnailTitle, generic = false }) => {
    return (
        <>
            <div className={styles.featured_recipe_container}>
                {title && (
                    <>
                        <h2 className={`yellow_border spacer__01 ${styles.featuredRecipeTitle}`}>{title}</h2>
                    </>
                )}
                <div className={styles.recipe_img_wrapper}>
                    <Img fluid={fluid} alt={fluidTitle} className={styles.recipe_img}></Img>
                    {
                        generic
                            ? <p className={`${generic && styles.subTitle}`}><span> {subtitle}</span></p>
                            : <p>{subtitle}</p>

                    }
                    <h3>{thumbnailTitle}</h3>
                </div>
            </div>
            <Banner heroTitle={thumbnailTitle} subtitle={subtitle} color="white" />
        </>
    )
}

export default FeaturedRecipe
