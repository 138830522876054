import React from "react"
import ProductCard from "../ProductCard/ProductCard"
import * as styles from "./featuredArticle.module.scss"

const FeaturedArticle = ({
  title,
  imgTitle,
  description,
  bgFluid,
  iconFluid,
  iconWhole=null,
  triangleLeft,
  link
}) => {
  return (
    <div className={`triangle_02 left ${styles.article_container}`}>
      {title && (
        <>
          <h2 className="yellow_border">{title}</h2>
        </>
      )}
      <div className={styles.img_wrapper}>
        <ProductCard
          fluid={iconFluid}
          icon={iconWhole}
          title={imgTitle}
          desc={description}
          bgImg={bgFluid}
          to={link}
        />
      </div>
      {/* <div
        className={`${
          triangleLeft
            ? styles.triangle_wrapper_left
            : styles.triangle_wrapper_right
        }`}
      >
        <div className="triangle"></div>
      </div> */}
    </div>
  )
}

export default FeaturedArticle
