import Img from "gatsby-image"
import * as styles from "./PremiumBlackicon.module.scss";
import React from "react"
import Helmet from 'react-helmet';


const PremiumBlackicon = ({ fluid, fluidMob, heroTitle, heroTitleMobile }) => {

    return (
        <>
            {fluidMob &&
                <Helmet>
                    <link rel="preload" as="image" media="(min-width: 400.1px) and (max-width: 767px)" href={`${fluidMob.fluid.srcSet.split(/\s*,\s*/).slice(-1).join().split(" ")[0]}`} />
                    <link rel="preload" as="image" media="(max-width: 400px)" href={`${fluidMob.fluid.srcSet.split(/\s*,\s*/).slice(-2).join().split(" ")[0]}`} />
                </Helmet>
            }
            <div className={styles.imgContainer}>
                {fluidMob &&
                    <div className={`${styles.mobImage}`}>
                        <div aria-hidden="true" style={{ width: '100%', paddingBottom: '142.364%' }}></div>
                        <picture>
                            <source media="(min-width: 400.1px) and (max-width: 767px)" srcSet={`${fluidMob.fluid.srcSet.split(/\s*,\s*/).slice(-1).join().split(" ")[0]}`} />
                            <source media="(max-width: 400px)" srcSet={`${fluidMob.fluid.srcSet.split(/\s*,\s*/).slice(-2).join().split(" ")[0]}`} />
                            <img fetchpriority="high" src={`${fluidMob.fluid.src}`}
                                loading="lazy"
                                alt={heroTitleMobile ? heroTitleMobile : "Hero Image"}
                            />
                        </picture>
                    </div>
                }
                <Img
                    fluid={fluid.fluid}
                    className={`${fluidMob ? styles.deskImage : styles.heroImg}`}
                    alt={heroTitle ? heroTitle : "Hero Image"}
                    loading="lazy"
                    fadeIn={true}
                />
            </div>
        </>
    )
}

export default PremiumBlackicon