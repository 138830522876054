import React, { useState } from "react"
import GridSection from "../GridSection/GridSection"
import * as styles from "./alternateContainer.module.scss"

const alternateContainer = ({ alternativeSection, slug,downloadasset, slugTitle }) => {
  return (
    <>
      <div className={styles.sectionContainer}>
        {alternativeSection.map(alternative => {
          return (
            <GridSection
              title={alternative?.title}
              image={alternative.gridImages}
              backgroundImage={alternative.backgroundImage}
              slug={alternative?.slug}
              imageSize={alternative?.imageSize}
            />
          )
        })}
      </div>
      {slugTitle && (
        <div className={styles.dowloadpdf}>
          {/* <a target="_blank" href={downloadasset}> */}
          <a href={downloadasset?.file?.url} target="_blank" download>{slugTitle}</a>
        </div>
      )}
    </>
  )
}
export default alternateContainer
