import React from "react";
import * as styles from "./SectionDualImage.module.scss";
import SectionImageText from "../section-image-text/SectionImageText";

const SectionDualImage = ({ data }) => {
    const { images } = data;

    // Check if images array exists and has elements
    if (!images || images.length === 0) {
        return null; // or any other fallback content you want to render
    }

    return (
        <div>
            <div className={styles.imagesContainer}>
                {images.map((image, index) => {
                    return <SectionImageText image={image} key={index} />;
                })}
            </div>
        </div>
    );
};

export default SectionDualImage;
