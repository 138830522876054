import React from "react"
import * as styles from "./sustainabilityArticles.module.scss"
import Slider from "react-slick"
import ProductCard from "../ProductCard/ProductCard"

const SustainabilityArticles = ({ productCardsData, title }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  }

  return (
    <div className={styles.products_cards}>
      {title && (
        <>
          <h3>{title}</h3>
        </>
      )}
      <Slider {...settings}>
        {productCardsData.map(product => {
          return (
            <div className={styles.img_container}>
              <ProductCard
                desc={product.title}
                title={product.thumbnailCategoryTitle}
                bgImg={product.thumbnailImage[0].fluid}
                to={product.slug}
                fluid={product.articlePageIcon?.fluid}
              ></ProductCard>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SustainabilityArticles
