// extracted by mini-css-extract-plugin
export var desktopImage = "PackShot-module--desktop-image--4d4d9";
export var gridImages = "PackShot-module--gridImages--b648e";
export var heading = "PackShot-module--heading--a0476";
export var left_leaf = "PackShot-module--left_leaf--7e2bc";
export var media = "PackShot-module--media--a2cf1";
export var multipleImagesContainer = "PackShot-module--multipleImagesContainer--22e3a";
export var packs = "PackShot-module--packs--defd1";
export var packshot_packs = "PackShot-module--packshot_packs--0fadd";
export var productImage = "PackShot-module--productImage--e0763";
export var right_leaf = "PackShot-module--right_leaf--6a928";
export var underlinedText = "PackShot-module--underlinedText--e83df";