import React from "react"
import * as styles from "./SectionImageText.module.scss";
import Img from 'gatsby-image';
import { useLocalPath } from "../../../hooks"
import { Link } from "gatsby"

const SectionImageText = ({ image }) => {

    const { imageText, slug } = image;
    const img = image.image;
    return (

        <div className={styles.ImgContainer}>
            {slug ? <Link target="_blank" className={styles.lnk} to={useLocalPath(slug)}>
                        {img && <Img className={styles.Img} fluid={img.fluid} alt={img?.title} />}
                        {imageText && <h3 className={styles.imageText}>{imageText}</h3>}
                    </Link> :
                <>
                    {img && <Img className={styles.Img} fluid={img.fluid} alt={img?.title} />}
                    {imageText && <h3 className={styles.imageText}>{imageText}</h3>}
                </>
            }
        </div>
    )

}

export default SectionImageText
