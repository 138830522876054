import React, { useEffect, useState } from "react";
import * as styles from "./recipe-section.module.scss";
import RelatedRecipes from "../../../Recipes/RelatedRecipes/RelatedRecipes";

const newArticleArray = (articles) => {
  let newArray = [];
  let gridItems = [];
  articles.sectionThumbnail.forEach((article, index) => {
    if (articles.sectionThumbnail.length % 2 === 0) {
      if ((index + 1) % 2 === 0) {
        gridItems.push([
          articles.sectionThumbnail[index - 1],
          articles.sectionThumbnail[index]
        ]);
        newArray.push(gridItems);
        gridItems = [];
      }
    } else {
      if (index + 1 === articles.sectionThumbnail.length) {
        gridItems.push([articles.sectionThumbnail[index]]);
        newArray.push(gridItems);
      } else if ((index + 1) % 2 === 0) {
        gridItems.push([
          articles.sectionThumbnail[index - 1],
          articles.sectionThumbnail[index]
        ]);
        newArray.push(gridItems);
        gridItems = [];
      }
    }
  });
  return newArray;
};

const RecipeSection = ({ articles }) => {
  const [articlesArr, setArticlesArr] = useState(newArticleArray(articles));

  useEffect(() => {
    setArticlesArr(newArticleArray(articles));
  }, [articles]);

  return (
    <div className={styles.articles_container}>
      {articles.title && <h2 className="yellow_border">{articles.title}</h2>}
      <div className={styles.articles}>
        {articlesArr[0]?.map((articlePair, index) => {
          if ((index + 1) % 2 !== 0) {
            return (
              <RelatedRecipes
                key={index}
                recipes={articlePair}
                widthLeft="30%"
                widthRight="70%"
                icon={articles.icon}
              />
            );
          } else {
            return (
              <RelatedRecipes
                key={index}
                recipes={articlePair}
                widthLeft="70%"
                widthRight="30%"
                icon={articles.icon}
              />
            ); 
          }
        })}
      </div>
    </div>
  );
};

export default RecipeSection;
