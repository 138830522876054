// extracted by mini-css-extract-plugin
export var article_title = "landing-page-hero-module--article_title--edf59";
export var bg_Img = "landing-page-hero-module--bg_Img--54b97";
export var black = "landing-page-hero-module--black--2596e";
export var cat_title = "landing-page-hero-module--cat_title--29f1e";
export var childTitle = "landing-page-hero-module--child-title--cbf12";
export var desc = "landing-page-hero-module--desc--9338d";
export var hero_container = "landing-page-hero-module--hero_container--039ea";
export var icon = "landing-page-hero-module--icon--6c5d2";
export var mobileImage = "landing-page-hero-module--mobileImage--09842";
export var purposePage = "landing-page-hero-module--purpose-page--fa274";
export var seoHeading = "landing-page-hero-module--seo-heading--8498a";
export var shortDescription = "landing-page-hero-module--shortDescription--7c7bc";
export var socialIcons = "landing-page-hero-module--social-icons--329fb";
export var text_container = "landing-page-hero-module--text_container--d0b7b";
export var titleContainer = "landing-page-hero-module--title-container--49c16";