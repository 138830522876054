import React from "react"
import Img from "gatsby-image"
import * as styles from "./InfographicsHero.module.scss"

const InfographicsHero = ({ fluid, fluidMob, title, heroTitle, hideheadingTitle, heroTitleMobile }) => {
    return (
        <div className={styles.imgContainer}>
            {/* Preload the LCP image */}
            <link rel="preload" href={fluid.fluid.src} as="image" />

            {fluidMob && (
                <picture>
                    <source media="(min-width: 400.1px) and (max-width: 767px)" srcSet={fluidMob.fluid.srcSet} />
                    <source media="(max-width: 400px)" srcSet={fluidMob.fluid.srcSet} />
                    <img
                        src={fluidMob.fluid.src}
                        srcSet={fluidMob.fluid.srcSet}
                        sizes={fluidMob.fluid.sizes}
                        alt={heroTitleMobile ? heroTitleMobile : "Hero Image"}
                        className={styles.mobImage}
                        style={{ width: '100%' }} // Let Gatsby handle height
                    />
                </picture>
            )}

            {/* Use Gatsby Image component for optimal lazy loading */}
            <Img
                fluid={fluid.fluid}
                className={fluidMob ? styles.deskImage : styles.heroImg}
                alt={heroTitle ? heroTitle : "Hero Image"}
                fadeIn={true}
                style={{ width: '100%' }} // Let Gatsby handle height
                imgStyle={{ width: '100%', height: 'auto' }} // Set explicit width and auto height
            />
            <div className={styles.title}>
                <h1 className={styles.hide}>{hideheadingTitle ? hideheadingTitle : "Green Tea Essentials: Enhance Your Life with Every Sip"}</h1>
            </div>
        </div>
    )
}

export default InfographicsHero
