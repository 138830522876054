import React, { useRef } from "react"
import * as styles from "./SectionCardContainer.module.scss";
import SectionInfoCard from "../section-info-card/SectionInfoCard";
import SectionAnimationCard from "../section-animation-card/SectionAnimationCard";


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const SectionCardContainer = ({ data }) => {
    const { name, sectionHeading, cards,campaign } = data
    const swiperRef = useRef(null)

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }

    switch (cards[0].__typename) {

        case ("ContentfulComponentAnimationCard"):
            let className = campaign === 'LYL' ? styles.lylpage : styles.animationCardsContainer;
            return <div>
                {sectionHeading && <h2 className={`${`yellow_border`} ${styles.animation_heading}`}>{sectionHeading}</h2>}
                <div className={className}>
                    {cards.map((card, index) =>
                        <SectionAnimationCard card={card} index={index + 1} />
                    )}
                </div>
            </div>

        case (undefined):
            return (
                <div className={`triangle_02_green right ${`styles.article_container`}`}>
                    <h2 className={`yellow_border_green spacer__01 ${`styles.featuredProductTitle`}`}>{sectionHeading}</h2>
                    <div className={styles.swiperBox}>
                        <div className={`${styles.prevButton}`} onClick={goPrev}></div>
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={10}
                            centeredSlides={true}
                            loop={true}
                            breakpoints={{
                                360: {
                                    slidesPerView: 1.2
                                },
                                500: {
                                    slidesPerView: 1.65
                                }
                            }}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index}>
                                    <SectionInfoCard card={card} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={`${styles.nextButton}`} onClick={goNext}></div>
                    </div>
                    <div className={styles.cardsBox}>
                        {cards.map((card) =>
                            <SectionInfoCard card={card} />
                        )}
                    </div>
                </div>
            );
        default:
    }
}

export default SectionCardContainer;