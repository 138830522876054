// extracted by mini-css-extract-plugin
export var animationCardsContainer = "SectionCardContainer-module--animation-cards-container--2e2e6";
export var animation_heading = "SectionCardContainer-module--animation_heading--d5aa9";
export var cardsBox = "SectionCardContainer-module--cards-box--38503";
export var cardsContainer = "SectionCardContainer-module--cards-container--0d2f3";
export var lylpage = "SectionCardContainer-module--lylpage--f49ae";
export var nextButton = "SectionCardContainer-module--next-button--af96c";
export var prevButton = "SectionCardContainer-module--prev-button--eeeac";
export var swiperBox = "SectionCardContainer-module--swiper-box--9b6c6";
export var swiperContainer = "SectionCardContainer-module--swiper-container--36067";
export var title = "SectionCardContainer-module--title--418ab";