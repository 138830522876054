import React, { useRef, useState } from "react";
import * as styles from "./SectionAnimationCard.module.scss";
import { RichText } from "../../Layout/RichText/RichText";

const SectionAnimationCard = ({ card, index }) => {
    const { frontText, richBackText, frontColor, backColor, type } = card;
    const order = index;

    // State to track if the card is flipped
  const [isFlipped, setFlipped] = useState(false);

  // Function to handle flip on mobile touch
  const handleTouchFlip = () => {
    setFlipped(!isFlipped);
  };




    if (type === "Lipton Yellow Label" && (order === 1 || order === 3 || order === 6 || order === 8 || order === 5)) {
        return (
            <div className={styles.flippableCardContainer}
            onClick={handleTouchFlip} // Click event for desktop
            onTouchStart={handleTouchFlip} // Touch event for mobile
            >
                <div className={styles.card} style={{ backgroundColor: frontColor }}>
                    <div className={styles.cardBackLabel} style={{ backgroundColor: backColor }}>
                        < RichText content={richBackText}  />
                    </div>
                    <div className={styles.cardFrontYellow}>{frontText}</div>
                </div>
            </div>
        );
    } else if (type === "Lipton Green Label" && (order === 1 || order === 3 || order === 6 || order === 8)) {
        return (
            <div className={styles.flippableCardContainer}
            onClick={handleTouchFlip} // Click event for desktop
            onTouchStart={handleTouchFlip} // Touch event for mobile
            >
                <div className={styles.card} style={{ backgroundColor: frontColor }}>
                    <div className={styles.cardBack}>
                        <RichText content={richBackText} />
                    </div>
                    <div className={styles.cardFrontRed}>{frontText}</div>
                </div>
            </div>
        );
    } else if (type === "Lipton Yellow Label") {
        return (
            <div className={styles.flippableCardContainer}
            onClick={handleTouchFlip} // Click event for desktop
            onTouchStart={handleTouchFlip} // Touch event for mobile
            >
                <div className={styles.card} style={{ backgroundColor: backColor }}>
                    <div className={styles.cardBackLabel} style={{ backgroundColor: frontColor }} >
                        <RichText content={richBackText} />
                    </div>
                    <div className={styles.cardFrontRed}>{frontText}</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.flippableCardContainer}
            >
                <div className={styles.card} style={{ backgroundColor: backColor }}>
                    <div className={styles.cardBack}>
                        <RichText content={richBackText} />
                    </div>
                    <div className={styles.cardFrontRed}>{frontText}</div>
                </div>
            </div>
        );
    }
};

export default SectionAnimationCard;
