import React from "react"
import Img from "gatsby-image"
import * as styles from "./imageContainer.module.scss"

const ImageSection = ({ image }) => {
  return (
    <div className={styles.cardContainer}>
        <Img
          className={`styles.iconImage`}
          fluid={image.fluid}
          alt={image?.title}
        />
    </div>
  )
}

export default ImageSection
