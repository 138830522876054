import React from "react"
import * as styles from "./SectionNarrowText.module.scss"
import { RichText } from "../../Layout/RichText/RichText"
// import { divide } from "lodash"

const SectionNarrowText = ({ data }) => {

    const {textualContent,sourceText} = data

    return (
        <div className={styles.mainContainer}>
            {textualContent && <div className={styles.narrowText}>
                <RichText content={textualContent} />
            </div>}

            {sourceText && <div className={styles.sourceText}>
                <RichText content={sourceText}/>
                </div>}
        </div>
        
    )
}

export default SectionNarrowText;
