import React from "react";
import * as styles from "./TextSection.module.scss";
import { RichText } from '../../../Layout/RichText/RichText';

const TextSmall = ({ paragraph }) => (
    <div className={styles.textContainer}>
        <div className='container_lg'>
            <RichText content={paragraph} />
        </div>
    </div>
);

export default TextSmall;
