import React from "react"

import * as styles from "./sunshine.module.scss"

const Sunshine = ({ title, description }) => {
  return (
    <div className={styles.container}>
      {title && (
        <h2 className={`yellow_border`}>
          <span>{title}</span>
        </h2>
      )}
      {description && (
        <p className={` ${styles.homeDesc}`}>
          <span>{description}</span>
        </p>
      )}
    </div>
  )
}

export default Sunshine
