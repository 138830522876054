import { graphql } from "gatsby"
import React, {useEffect, useRef, useState} from "react"
import Articles from "../components/Shared/Generic/ArticlesSection/Articles"
import GenericHero from "../components/Shared/Generic/Hero/GenericHero"
import FeaturedVideo from "../components/Shared/FeaturedVideoSection/FeaturedVideo"
import FeaturedRecipe from "../components/Shared/FeaturedRecipeSection/FeaturedRecipe"
import ReccomendedProducts from "../components/Shared/ReccomendedProducts/ReccomendedProducts"
import SustainabilityArticles from "../components/Shared/SustainabilityArticles/SustainabilityArticles"
import FeaturedArticle from "../components/Shared/FeaturedArticleSection/FeaturedArticle"
import FeaturedProduct from "../components/Shared/FeaturedProductSection/FeaturedProduct"
import TextComponent from "../components/Shared/TextComponent/TextComponent"
import TextSmall  from "../components/Shared/TextSmall/TextSmall"
import Seo from "../components/seoGlobal"
import { useLocale } from "../hooks"
import RecipeSection from "../components/Shared/Generic/RecipeSection/RecipeSection"
import Sunshine from "../components/Shared/Sunshine/Sunshine"
import PurposeVideo from "../components/Shared/PurposeVideo/PurposeVideo"
import Schema from "../components/schema"
import { Slider } from "../components/Shared/Slider/Slider"
import { SectionFeatured } from "../components/Shared/SectionFeatured/SectionFeatured"
import HeropannelSE from "../components/Shared/Generic/Heropannel/HeropannelSE"
import AlternateContainer from "../components/sections/AlternativeSection/alternateContainer"
import AlternateImagesection from "../components/sections/Alternative-section-Image/alternateImagesection"
import { Helmet } from 'react-helmet';
import loadable from "@loadable/component"
const ArticleContentMedia = loadable(
  () => import("../ArticlePage/ArticleContentMedia/ArticleContentMedia")
)
import SectionCardContainer from "../components/sections/section-card-container/SectionCardContainer"
import ArticleContent from "../ArticlePage/ArticleContent/ArticleContent"
import LandingPageHero from "../components/Shared/Generic/LandingPageHero/LandingPageHero"
import SectionHeadingDescription from "../components/sections/section-heading-description/sectionHeadingDescription"
import { getPathname } from "../utils/functions"
import SectionDualImage from "../components/sections/section-dual-image-container/SectionDualImage"
import SectionHighlightedDescription from "../components/sections/section-highlighted-description/SectionHighlightedDescription"
import SectionMultipleImages from "../components/sections/section-multiple-images/SectionMultipleImages"
import InfographicsHero from "../components/Shared/Generic/InfographicsHero/InfographicsHero"
import InfographicsHeroNarrow from "../components/Shared/Generic/InfographicsHeroNarrow/InfographicsHeroNarrow"
import SectionNarrowText from "../components/sections/section-narrow-text/SectionNarrowText"
import SectionAlternateGrid3 from "../components/sections/section-alternate-grid-3/SectionAlternateGrid3"
import TextSection from "../components/Shared/Generic/Hero/TextSection"
import YoutubeSection from "../components/Shared/Generic/Hero/YoutubeSection/YoutubeSection"
import PackShotSection from "../components/Shared/Generic/PackshotSection/PackShotSection"
import PremiumBlackicon from "../components/Shared/Generic/PackshotSection/PremiumBlackicon"
import FinestTeaBanner from "../components/Shared/Generic/Hero/Finest_tea_arabia_hero/FinestTeaBanner"
import SectionAlternateGrid from "../ArticlePage/SectionAlternateGrid/SectionAlternateGrid"

const Generic = ({
  location,
  pageContext,
  data: {
    contentfulPageGeneric: {
      title,
      slug,
      customHreflang,
      seoTitle,
      bvScript,
      sections,
      relatedArticles,
      seoDescription,
      googleSiteVerificationId,
    },
    allContentfulPageGeneric: { nodes: allLinks },
  },
}) => {
  const locale = useLocale()
  const newItem = [ {"slug": "/", "node_locale": "en-US"},  {"slug": "/", "node_locale": "en-CA"}, {"slug": "/", "node_locale": "fr-CA"}];

  // BV UGC carousel asked to remove on 01/07/24--------------------------------------------
  // const useCuralateScript = () => {
  //   const handleLoadRef = useRef();
    
  //   useEffect(() => {
  //     if (!bvScript) return;

  //     const embedCuralateScript = () => {
  //       const CRL8_SITENAME = 'lipton-hrz117';
  //       const baseUrl = `https://edge.curalate.com/sites/${CRL8_SITENAME}/site/latest/`;
  //       const scriptUrl = `${baseUrl}site.min.js`;
        
  //       // Add preconnect for faster DNS resolution
  //       const preconnectLink = document.createElement('link');
  //       preconnectLink.rel = 'preconnect';
  //       preconnectLink.href = 'https://edge.curalate.com';
  //       document.head.appendChild(preconnectLink);
        
  //       // Load script
  //       const script = document.createElement('script');
  //       script.src = scriptUrl;
  //       script.defer = true; // Execute scripts in order
  //       script.onerror = () => console.error('Failed to load the Curalate script.');
  //       document.head.appendChild(script);
  //     };
  
  //     handleLoadRef.current = () => {
  //       embedCuralateScript();
  //       window.removeEventListener('load', handleLoadRef.current);
  //     };
  
  //     if (document.readyState === 'complete') {
  //       // Document already loaded, embed the script
  //       embedCuralateScript();
  //     } else {
  //       window.addEventListener('load', handleLoadRef.current);
  //     }
  
  //     // Cleanup function to remove event listener
  //     return () => {
  //       window.removeEventListener('load', handleLoadRef.current);
  //     };
  //   }, [bvScript]);
  // };
  
  // useCuralateScript();


  return (
    <div>
      <Seo
        title={seoTitle}
        lang={locale}
        description={seoDescription?.seoDescription}
        hrefLangs={customHreflang ? newItem : allLinks}
        verificationId={googleSiteVerificationId}
      />
      <Schema slug={getPathname(pageContext.node_locale,slug)}/>
      {sections &&
        sections.map((data: any, index: number) => {
          if (data.__typename === "ContentfulComponentSunshine") {
            return (
              <Sunshine
                title={data.heading}
                description={data.description.description}
                key={index}
              />
            )
          }
          else if (data.__typename === "ContentfulComponentYoutubeMedia") {
            return (
              <PurposeVideo
                description={data.heading}
                longDescription={data.longDescription}
                title={data.genericDesc}
                backgroundVideo={data.youtubeVideo}
                backgroundImage={data.bannerImage}
                home={true}
                key={index}
                autoplay={data.autoplay}
              />
            )
          }

          else if (data.__typename === "ContentfulComponentYoutubeAdd") {
            return (
              <YoutubeSection
                description={data.heading}
                longDescription={data.longDescription}
                title={data.genericDesc}
                backgroundVideo={data.youtubeVideo}
                backgroundImage={data.bannerImage}
                home={true}
                key={index}
                autoplay={data.autoplay}
              />
            )
          }
         
        
          
          else if (data.__typename === "ContentfulHeropanel") {
            return (
              <HeropannelSE
                title={data.title}
                subtitle={data.subtitle}
                fluid={data.sectionImage.fluid}
              />
            )
          }
          else if (
            data.__typename === "ContentfulComponentAlternativeSection"
          ) {
            return (
              <AlternateContainer
                alternativeSection={data.alternativeSection}
                slugTitle={data.slugTitle} downloadasset={data.downloadasset}                
              />
            )
          }
          
          else if (data.__typename === "ContentfulTextSection") {
            return <TextSection title={data.title}  paragraph={data.paragraph} key={index} 
           />
          }
          
          else if (data.__typename === "ContentfulAlternativeSectionImage") {
            return <AlternateImagesection slideImages={data.slideImages} />
          } else if (
            data.__typename === "ContentfulComponentHeadingDescription"
          ) {
            return <SectionHeadingDescription section={data} />
          } else if (
            data.__typename === "ContentfulComponentRelatedProductsArticles"
          ) {
            return <Slider data={data} key={index} />
          }else if (
            data.__typename === "ContentfulCommonCarousel"
          ) {
            return <Slider data={data} />
          } else if (data.__typename === "ContentfulComponentFeatured") {
            return <SectionFeatured data={data} pageSlug={slug} />
          } else if (data.__typename === "ContentfulComponentContentMedia") {
            return (
              <ArticleContentMedia
                heading={data.heading}
                image={data.mediaImage}
                key={index}
              />
            )
          } else if (data.__typename === "ContentfulComponentCardsContainer") {
            return <SectionCardContainer data={data} />
          } else if (data.__typename === "ContentfulDualImageComponent") {
            
            return (
              <SectionDualImage data={data}/>
            )
          } else if (data.__typename === "ContentfulComponentHighlightedDescription") {
            return (
              <SectionHighlightedDescription data={data}/>
            )
          } 
          else if (data.__typename === "ContentfulPackshotSection") {
            return (
              <PackShotSection data={data} />
            )
          }           
          else if (data.__typename === "ContentfulComponentNarrowText") {
            return (
              <SectionNarrowText data={data} key={index}/>
            )
          } else if (data.__typename === "ContentfulComponentAlternateGrid3") {
            return (
              <SectionAlternateGrid3 data={data} key={index}/>
            )
          } 

          else if (data.__typename === "ContentfulComponentAlternateGrid") {
            return (
              <SectionAlternateGrid
              position={data.positionContent}
              sectionContent={data.sectionContent}
              key={index}
              sectionImage={data.sectionImage}
              title={data.title}
              key={index}
              type={data.imageSize ? data.imageSize : "Default"}
              buttonText={data.buttonText}
              buttonSlug={data.buttonSlug}
            />
            )}
         
          else if (data.__typename === "ContentfulComponentMultipleImages") {
            return (
              <SectionMultipleImages  data={data}/>
            )
          }
           else {
            switch (data.type) {
              case "landingPageHero":
                return (
                  <LandingPageHero
                    subtitle={data.description?.description}
                    title={data.title}
                    fluid={data.backgroundImage.fluid}
                    fluidMob={data.backgroundImageMobile ? data.backgroundImageMobile : null}
                    fluidIcon={data?.recipesIcon?.fluid}
                    fluidText={data.backgroundImage.title}
                    key={index}
                    firstChild={index === 0}
                  />
                )
              case "mainhero":
                return (
                  <GenericHero
                    subtitle={data.description?.description}
                    title={data.title}
                    fluid={data.backgroundImage.fluid}
                    fluidMob={data.backgroundImageMobile ? data.backgroundImageMobile.fluid : null}
                    fluidIcon={data?.recipesIcon?.fluid}
                    fluidText={data.backgroundImage.title}
                    key={index}
                    firstChild={index === 0}
                  />

                )
                case "Infographics Hero":
                return (
                  <InfographicsHero 
                    fluid={data.backgroundImage}
                    heroTitle={data.backgroundImage.title}
                    //heroTitleMobile={data.backgroundImage.title ? data.backgroundImageMobile.title : null}
                    fluidMob={data.backgroundImageMobile ? data.backgroundImageMobile : null}
                    key={index}/>

                )

                case "Finest Banner":
               return (
                 <FinestTeaBanner
                   fluid={data.backgroundImage}
                   heroTitle={data.backgroundImage.title}
                   fluidMob={data.backgroundImageMobile ? data.backgroundImageMobile : null}
                   key={index}/>

               )

                case "component hero packshot":
               return (
                 <PremiumBlackicon
                   fluid={data.backgroundImage}
                   heroTitle={data.backgroundImage.title}
                   //heroTitleMobile={data.backgroundImage.title ? data.backgroundImageMobile.title : null}
                   fluidMob={data.backgroundImageMobile ? data.backgroundImageMobile : null}
                   key={index}
                 />

               )

                case "ContentfulTextSection":
                return (
                  <TextSection
                  title={data.title} key={index}/>

                )

                case "Infographics Hero Narrow":
                return (
                  <InfographicsHeroNarrow
                    slug={data.slug}
                    fluidTitle={data.title}
                    fluid={data.backgroundImage}
                    fluidMob={data.backgroundImageMobile ? data.backgroundImageMobile : null}
                    fluidText={data.backgroundImage.title}
                    key={index}/>
                    
                )
              case "componentGridItems":
                return <Articles articles={data} key={index} />
              case "componentTextOnly":
                return <TextComponent text={data.content} key={index} />
              case "componentTextOnly2":
                return (
                  <ArticleContent
                    content={data.content}
                    alignment={data.alignment}
                    key={index}
                  />
                )
                case "componentSmallText":
                    return <TextSmall text={data.content} key={index} />
              case "componentFeaturedArticle":
                return (
                  <FeaturedArticle
                    title={data.title}
                    imgTitle={data.subtitle}
                    description={data.sectionThumbnail[0].thumbnailTitle}
                    bgFluid={data.sectionThumbnail[0].thumbnailImage[0].fluid}
                    iconFluid={data.icon.fluid}
                    iconWhole={data.icon}
                    key={index}
                    link={data.sectionThumbnail[0].slug}
                  />
                )
              case "componentFeaturedRecipe":
                return (
                  <FeaturedRecipe
                    fluid={data.sectionThumbnail[0].thumbnailImage[0].fluid}
                    thumbnailTitle={
                      data.name === "Wellbeing recipe section"
                        ? data.sectionThumbnail[0].thumbnailTitle
                        : ""
                    }
                    fluidTitle={
                      data.name === "Wellbeing recipe section"
                        ? data.sectionThumbnail[0].title
                        : ""
                    }
                    subtitle={data.sectionThumbnail[0].thumbnailCategoryTitle}
                    title={data.sectionThumbnail[0].title}
                    key={index}
                    generic={true}
                  />
                )
              case "componentFeaturedProduct":
                return (
                  <FeaturedProduct featuredProductData={data} key={index} />
                )
              case "componentRelatedItems":
                return (
                  <ReccomendedProducts
                    reccomendedProductsData={data.sectionThumbnail}
                    showButton={true}
                    key={index}
                  />
                )
              case "pagehero":
                return <FeaturedVideo featuredVideoData={data} key={index} />
              case "recipes":
                return <RecipeSection articles={data} />
              default:
                return null
            }
          }
        })}
      {relatedArticles && (
        <SustainabilityArticles productCardsData={relatedArticles} />
      )}
      {/* {bvScript && <div style={{marginTop:'40px'}} data-crl8-container-id="homepage"></div>} */}
      {/* <div data-crl8-container-id="product" data-crl8-filter="productId:[041000077203,041000008429,041000100291,041000072376]"></div> */}
      {/* {bvScript && <div style={{marginTop:'40px'}} data-crl8-container-id="product"></div>} */}
    </div>
  )
}

export const GenericQuery = graphql`
  query ArticlePage($id: String!, $contentful_id: String!) {
    contentfulPageGeneric(id: { eq: $id }) {
      node_locale
      slug
      title
      seoTitle
      customHreflang
      bvScript
      seoDesc {
        raw
      }
      seoDescription {
        seoDescription
      }
      slug
      googleSiteVerificationId
      sections {
        ... on ContentfulComponentFeatured {
          __typename
          title
          featuredItem {
            ... on ContentfulPageProducts {
              __typename
              thumbnailDescription {
                thumbnailDescription
              }
              slug
              title
              thumbnailImage {
                title
                fluid(maxWidth: 1200, quality: 50) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              cta {
                ... on ContentfulComponentLink {
                  title
                }
              }
            }
          }
        }
        ... on ContentfulComponentRelatedProductsArticles {
          __typename
          dynamicBullets
          articleTriangleAlign
          navigation
          title
          heading
          smalltitle
          type
          centeredAlign
          articles {
            articlePageIcon {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            slug
            thumbnailCategoryTitle
            thumbnailTitle
            title
            thumbnailImage {
              title
              fluid(maxWidth: 2400, quality: 50) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          products {
            title
            slug
            thumbnailImage {
              title
              fluid(maxWidth:1200,quality: 40) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          recipes {
            title
            slug
            heroImage {
              title
              fluid(quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            subTitle
            recipeIcon {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          titleBarColor
        }
        ... on ContentfulComponentYoutubeMedia {
          __typename
          bannerImage {
            title
            fluid(maxWidth:2000, quality: 60) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heading
          genericDesc: description
          longDescription{
            longDescription
          }
          autoplay
          youtubeVideo
        }

      

        ... on ContentfulComponentAlternativeSection {
          __typename
          slugTitle
          alternativeSection {
            title
            gridImages {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            slug
            imageSize
          }
            downloadasset {
              file {
                url
                fileName
              }
            }
          }
        ... on ContentfulAlternativeSectionImage {
          __typename
          name
          slideImages {
            imageSection {
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
        
        ... on ContentfulComponentHero {
          id
          name
          title
          recipesIcon {
            title
            fluid( quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          description {
            description
          }
          backgroundImage {
            title
            fluid(maxWidth: 1200, quality: 50) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          backgroundImageMobile {
            title
            fluid(quality: 50) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          type
        }
        ... on ContentfulComponentContent {
          id
          title
          type
          content {
            raw
          }
        }
        ... on ContentfulComponentFeaturedItems {
          id
          name
          title
          heading
          subtitle
          type
          icon {
            title
            fluid  {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          slug
          sectionThumbnail {
            ... on ContentfulPageArticle {
              __typename
              id
              name
              title
              slug
              subTitle: thumbnailCategoryTitle
              thumbnailTitle
              thumbnailImage {
                title
                fluid(quality: 75) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            ... on ContentfulPageProducts {
              __typename
              thumbnailDescription {
                thumbnailDescription
              }
              slug
              title
              thumbnailTitle
              productImg: thumbnailImage {
                title
                fluid(quality: 75) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            ... on ContentfulPageRecipe {
              __typename
              heroImage {
                fluid(quality: 75) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              slug
              title
            }
          }
        }
       
        ... on ContentfulComponentAlternateGrid {
          __typename
          name
          title
          positionContent
          sectionImage {
              title
              fluid(maxWidth: 1200, quality:60) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }
          sectionContent {
              raw
          }
          imageSize
          buttonText
          buttonSlug
      }
      ... on ContentfulDualImageComponent {
        __typename
        name
        images {
          ... on ContentfulImageTextComponent {
            slug
            name
            imageText
            image {
              title
              fluid(maxWidth:2000, quality: 50) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }

        ... on ContentfulComponentCardsContainer {
          __typename
          name
          campaign
          sectionHeading
          cards {
            ... on ContentfulComponentInfoCard {
              name
              title
              subTitle
              description
              logo {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              thumbnailImage {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            
            ... on ContentfulComponentAnimationCard {
              __typename
              name
              frontText
              frontColor
              backColor
              richBackText{
                raw
              }
              frontColor
              backColor
              type
            }
          }
        }
        ... on ContentfulComponentFeatured {
          __typename
          title
          backgroundColor
          featuredItem {
            ... on ContentfulPageRecipe {
              __typename
              title
              slug
              heroImage {
                title
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              subTitle
            }
          }
        }
       
        ... on ContentfulComponentHighlightedDescription {
          __typename
          name
          disclaimerText{
            raw
          }
          simpleText{
            raw
          }
          coloredText{
            coloredText
          }
        }
        ... on ContentfulComponentMultipleImages {
          __typename
          name
          heading
          subHeading
          mediaCaptions {
            captionTitle
            captionDesc
            asset {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on ContentfulCommonCarousel{
          __typename
          title
          type
          centeredAlign
          navigation
          dynamicBullets
          articleTriangleAlign
          carouselItems{
            slug
            categoryThumbnailTitle
            thumbnailTitle
            thumbnailIcon{
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            thumbnailImage {
              title
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    allContentfulPageGeneric(
      filter: {
        contentful_id: { eq: $contentful_id }
        id: { ne: $id }
        slug: { ne: null }
      }
    ) {
      nodes {
        slug
        node_locale
      }
    }
  }
`

export default Generic
