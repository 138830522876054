import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { useLocalPath } from "../../../hooks"
import * as styles from "./featuredProduct.module.scss"

const FeaturedProduct = ({ featuredProductData }) => {
    const { thumbnailTitle, thumbnailDescription, productImg, slug } =
        featuredProductData.sectionThumbnail[0]
    return (
        <div className={styles.featured_container}>
            <h2 className={`yellow_border spacer__01 ${styles.featuredProductTitle}`}>{featuredProductData.title}</h2>
            <div className={styles.product_row}>
                <Link to={useLocalPath(`/${slug}`)}>
                    <Img fluid={productImg?.fluid} alt={productImg?.title ? productImg?.title : thumbnailTitle} className={styles.productImg}></Img>
                </Link>
                <div className={styles.product_desc}>
                    <h3>
                        <Link to={useLocalPath(`/${slug}`)}>
                            {thumbnailTitle}
                        </Link>
                    </h3>
                    <p>{thumbnailDescription?.thumbnailDescription}</p>
                </div>
            </div>
        </div>
    )
}

export default FeaturedProduct
