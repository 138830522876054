import React from "react"
import * as styles from "./related-recipes.module.scss"
import ProductCard from "../../Shared/ProductCard/ProductCard"
import { useDictionaryQuery } from "../../../hooks"
const RelatedRecipes = ({ recipes, icon, widthLeft, widthRight }) => {
  if (!widthLeft) {
    widthLeft = "30%"
  }
  if (!widthRight) {
    widthRight = "70%"
  }
  const { recipeCategoryTitle } = useDictionaryQuery()
  return (
    <>
      <div>
        <div className={styles.product_grid_section}>
          <>
            <div
              className={styles.card_left_wrapper}
              style={{
                width: widthLeft,
                minWidth: `${widthLeft == "30%" ? "370px" : "0"}`,
              }}
            >
              <ProductCard
                fluid={icon?.fluid}
                title={recipeCategoryTitle}
                desc={recipes && recipes[0] && recipes[0].title}
                bgImg={
                  recipes &&
                  recipes[0] &&
                  recipes[0].heroImage &&
                  recipes[0].heroImage.fluid
                }
                to={recipes && recipes[0] && recipes[0].slug}
              />
            </div>
            {recipes?.length > 1 && (
              <div
                className={styles.card_right_wrapper}
                style={{
                  width: widthRight,
                  minWidth: `${widthLeft === "70%" ? "370px" : "0"}`,
                }}
              >
                <ProductCard
                  fluid={icon?.fluid}
                  title={recipeCategoryTitle}
                  desc={recipes && recipes[1] && recipes[1].title}
                  bgImg={
                    recipes &&
                    recipes[1] &&
                    recipes[1].heroImage &&
                    recipes[1].heroImage.fluid
                  }
                  to={recipes && recipes[1] && recipes[1].slug}
                />
              </div>
            )}
          </>
        </div>
      </div>
    </>
  )
}

export default RelatedRecipes
