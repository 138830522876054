import React from "react"
import * as styles from "./SectionInfoCard.module.scss";
import Img from 'gatsby-image';

const SectionInfoCard = ({ card }) => {

    const { title, subTitle, description, thumbnailImage, logo } = card

    return (
        <div className={styles.card}>
            <div className={styles.contentContainer}>
                <Img className={styles.logoImage} fluid={logo.fluid} alt={logo?.title} />
                <h3 className={styles.Title}>{title}</h3>
                <h2 className={styles.subTitle}>{subTitle}</h2>
                <p className={styles.description}>{description}</p>
            </div>
            <Img className={styles.thumbnailImage} fluid={thumbnailImage.fluid} alt={title}></Img>
        </div>
    )
}

export default SectionInfoCard;