import React, { useState } from "react"
import Img from "gatsby-image"
import { useLocalPath } from "../../../hooks"
import * as styles from "./gridContainer.module.scss"
import { Link } from "gatsby"
import { useSearchQuery } from "../../../hooks"

const GridSection = ({
  title,
  image,
  backgroundImage,
  slug,
  pdFtitle,
  imageSize,
  pdfdownloadsection,
}) => {
  const { pdfDnd } = useSearchQuery()
  return (
    <div className={styles.cardContainer}>
      {title && <h3 className={styles.heading}>{title}</h3>}
      {slug && (
        <Link to={useLocalPath(`/${slug}`)}>
          <Img
            className={imageSize ? styles.borderImage : `styles.iconImage`}
            fluid={image?.fluid}
            alt={image?.title}
          />
        </Link>
      )}
      {pdfDnd ? (
        <a target="_blank" href={pdfdownloadsection?.file?.url}>
          <Img
            className={styles.backgroundImg}
            fluid={backgroundImage?.fluid}
            alt={backgroundImage?.title}
          />
       </a>
      ) : (
        <Img
            className={styles.backgroundImg}
            fluid={backgroundImage?.fluid}
            alt={backgroundImage?.title}
          />
      )}
      {pdFtitle && <h3 className={styles.title}>{pdFtitle}</h3>}
    </div>
  )
}

export default GridSection
