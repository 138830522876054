import React from "react"
import * as styles from "./landing-page-hero.module.scss"
import Img from "gatsby-image"
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import { useLocation } from '@reach/router'
import { useLocale } from "../../../../hooks";
import { FacebookIcon, TwitterIcon, MailIcon } from "../../../../svgs/play";
import { split } from "lodash";
import Helmet from 'react-helmet'
const LandingPageHero = ({
    socialIcons,
    subtitle,
    title,
    description,
    shortDescription,
    fluid,
    fluidMob,
    fluidText = null,
    fluidIcon,
    altText,
    pageType,
    firstChild = false
}) => {
    const newTitle = title?.split('/');
    const shortDes = shortDescription?.split('/');
    const { href } = useLocation()
    const locale = useLocale()
    return (
        <>
            <div>
                <div className={styles.hero_container}>
                    <div className={`${styles.text_container} ${pageType === 'Purpose' && styles.purposePage} `}>
                        <Img loading={`${firstChild ? "eager" : "lazy"}`} fadeIn={firstChild ? false : true} fluid={fluidIcon} className={styles.icon} alt={altText ? altText : title ? title : "icon image"}></Img>
                        {/* <p className={`${styles.black} ${styles.cat_title}`}>{subtitle}</p>
                        <p className={styles.desc}>{description}</p> */}
                        {newTitle && <h1 className={styles.titleContainer}>{newTitle?.map((phrase, index) => {
                            return (
                                <span className={styles.childTitle} key={index} style={{ display: 'block' }}>
                                    {phrase}
                                </span>
                            )
                        })}</h1>}

                        {/* below line of code added just to ensure h1 heading should be there for seo correctness */}
                        {(!newTitle) ? <h1 className={styles.seoHeading}>Lipton Tea</h1>: null}

                        {/* <div className={`${styles.shortDes}`}>
                            {shortDes?.map((phrase, index) => {
                                return (
                                    <span key={index}>
                                        <p className={styles.black}>{phrase}</p>
                                    </span>
                                )
                            })}
                        </div> */}
                    </div>
                    {
                        socialIcons &&
                        <div className={styles.socialIcons}>
                            <FacebookShareButton url={href}>
                                <FacebookIcon width={45} height={45} color="#fade4b" />
                            </FacebookShareButton>
                            <EmailShareButton url={href}>
                                <MailIcon width={45} height={45} color="#fade4b" />
                            </EmailShareButton>
                            <TwitterShareButton url={href}>
                                <TwitterIcon width={45} height={45} color="#fade4b" />
                            </TwitterShareButton>
                        </div>
                    }
                    <Img loading={`${firstChild ? "eager" : "lazy"}`} fadeIn={firstChild ? false : true} fluid={fluid} className={styles.bg_Img} alt={fluidText ? fluidText : title ? title : "generic image"}></Img>
                    <div className={`styles.icons_wrapper`}></div>
                </div>
                <p className={styles.article_title}></p>
                {fluidMob && <Img
                fluid={fluidMob.fluid}
                className={` ${styles.mobileImage}`}/>} 

{!fluidMob ? <Img fluid={fluid} className={styles.mobileImage} alt={fluidText ? fluidText : title ? title : "generic background mobile image"}></Img> : null}
            </div>                 
        </>
    )
}

export default LandingPageHero