import React, { useState, useEffect, useRef } from "react";
import * as styles from "./YoutubeSection.module.scss";
import Img from 'gatsby-image';
import YoutubeVideo from "../../../YoutubeVideo";
import { Link } from "gatsby";
import { PlayIcon } from "../../../../../svgs/play";
import { YoutubePlay } from "../../../../../svgs/play";

import VideoSchema from "../../../../VideoSchema";

const YoutubeSection = ({
    title,
    description,
    longDescription,
    backgroundImage,
    backgroundVideo,
    home = false,
    buttonDetails = null,
    autoplay = false
}) => {
    const [playVideo, setPlayVideo] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (autoplay) {
            setPlayVideo(true);
        }
    }, [autoplay]);

    return (
        <>

        <VideoSchema 
            // slug={getPathname(pageContext.node_locale, slug)} 
            fluid={backgroundImage?.fluid}
            videoSrcURL={backgroundVideo}
            title={description ? description : "Lipton Tea Video"}
            description={title ? title : "Lipton Tea Video"}
        />

        <div className={`${styles.featured_video_container} ${styles.videoContainer}`}>
            <div className='container_lg'>
            {home ? (
                <div className={styles.homeVideo}>
                    {description && <h2 className={`yellow_border`}><span>{description}</span></h2>}
                    {title && <p className={` ${styles.homeDesc}`}><span>{title}</span></p>}
                    {longDescription && <p className={` ${styles.homeDesc}`}><span>{longDescription.longDescription}</span></p>}
                </div>
            ) : (
                <>
                    {title && <p className={styles.videoTitle}><span>{title}</span></p>}
                    {description && <h2 className={styles.videoDescription}><span>{description}</span></h2>}
                </>
            )}
            <div className={styles.video_wrapper}>
                <Img
                    fluid={backgroundImage?.fluid}
                    alt={backgroundImage?.title}
                    className={styles.featured_section_img}
                    />
                <div onClick={() => setPlayVideo(true)}>
                    <YoutubePlay className={styles.play_btn} height={undefined} width={undefined} />
                </div>
                {playVideo && backgroundVideo && (
                    <YoutubeVideo
                    width="100%"
                    height="100%"
                    className={`${styles.video} ${playVideo ? styles.show : ""}`}
                    videoSrcURL={backgroundVideo}
                    playVideo={playVideo}
                    />
                    )}
                    </div>
            </div>

            <div>
           
            </div>
        </div>
        </>
    );
}

export default YoutubeSection;